import { Injectable } from '@angular/core';
import { Observable, Subject ,  asyncScheduler } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';

import json from 'assets/configurations/config.json'
import { BrowserStorageHelper } from 'app/shared/helpers/browser-storage.helper';
import { UserService } from 'app/user/user-shared/user-service';


@Injectable()
export class AuthService {
  //private environmentConfig = inject(ENVIRONMENT_CONFIG);
  userSubject: Subject<boolean> = new Subject();

  constructor(private http: HttpClient,
    private browserStorageHelper: BrowserStorageHelper,
    private userService: UserService) { }

  getAccessToken(code: string): Observable<any> {
    const codeVerifier = this.browserStorageHelper.getCodeVerifier();

    return this.http.get<any>(json.portalApiEndpoint + `token?code=${code}&codeVerifier=${codeVerifier}`);
  }

  getNewAccessToken() {
    console.log('getting new token....')
    return this.http.get<any>(json.portalApiEndpoint + `refresh`);
  }

  signIn(): void {
    location.href = this.createUrl();
  }

  private createUrl(): string {
    const responseType = 'code';
    const state = this.createState();
    this.browserStorageHelper.setState(state);
    const codeChallenge = this.createCodeChallenge(75);
    const loginUri = json.voAcmConfig.loginUrl;
    const redirectUri = json.voAcmConfig.redirectUri;
    const scope = json.voAcmConfig.scope;

    const url = loginUri + '?' +
      'response_type=' + encodeURIComponent(responseType) +
      '&client_id=' + encodeURIComponent(json.voAcmConfig.clientId) +
      '&state=' + encodeURIComponent(state) +
      '&redirect_uri=' + encodeURIComponent(redirectUri) +
      '&scope=' + encodeURIComponent(scope) +
      '&code_challenge=' + codeChallenge +
      '&code_challenge_method=S256';

    return url;
  }

  signOut() {
    this.userService.deleteFromCache().subscribe(_ => {
      const postLogoutRedirectUri = encodeURIComponent(json.voAcmConfig.postLogoutRedirectUri);
      this.browserStorageHelper.removeIdToken();
      this.browserStorageHelper.removeExpiresAt();
      this.browserStorageHelper.removeUser();
      this.browserStorageHelper.removeState();
      this.browserStorageHelper.removeRedirectUrl();
      this.browserStorageHelper.removeRedirectQueryParams();
      this.browserStorageHelper.removeCodeVerifier();
      location.href = `${json.voAcmConfig.logoutUrl}?post_logout_redirect_uri=${postLogoutRedirectUri}`;
    });
  }

  userChanged(): Observable<boolean> {
    return this.userSubject;
  }

  emitUserChanged(value: boolean): void {
    this.userSubject.next(value);
  }

  isSignedIn(): boolean {
    const id_token = this.browserStorageHelper.getIdToken();
    if (id_token) {
      const expiresAt = this.browserStorageHelper.getExpiresAt();
      const now = new Date();
      if (expiresAt && parseInt(expiresAt, 10) < now.getTime()) {
        return false;
      }
      return true;
    }
    return false;
  }

  scheduleNewAccessTokenFetch(): void {
    const id_token = this.browserStorageHelper.getIdToken();

    if (id_token) {
      const expiresAt = this.browserStorageHelper.getExpiresAt();
      const expiresAtInSeconds = parseInt(expiresAt, 10);
      const now = new Date().getTime();
      const expiresIn = expiresAtInSeconds - now;
      const fourMinutesInMilliseconds = 4 * 60 * 1000;
      const milliSecondsBeforeRefresh = expiresIn - fourMinutesInMilliseconds;

      const renewToken = () => this.getNewAccessToken().subscribe(token => {
        this.setToken(token);
        this.scheduleNewAccessTokenFetch();
      });
      asyncScheduler.schedule(renewToken, milliSecondsBeforeRefresh);
    }
  }

  setToken(token): void {
    this.browserStorageHelper.setIdToken(token);
    this.browserStorageHelper.setExpiresAt(token.payload.exp);
  }

  getUserDisplayName() {
    const user = this.browserStorageHelper.getUser();
    return user && user.userContext ? user.userContext.contactName : '';
  }

  getAccountName() {
    const user = this.browserStorageHelper.getUser();
    return user && user.userContext ? user.userContext.actorName : '';
  }

  private createState() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  private createCodeVerifier(length: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  private createCodeChallenge(length: number = 75): string {
    let codeVerifier = this.createCodeVerifier(length);
    this.browserStorageHelper.setCodeVerifier(codeVerifier);

    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);

    return codeVerifierHash
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
  }
}

