import { User } from 'app/core/user';
import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';

@Injectable()
export class BrowserStorageHelper {

  constructor() {}

  setUser(user: User): void {
    sessionStorage.setItem('User', JSON.stringify(user));
  }

  getUser(): User {
    return JSON.parse(sessionStorage.getItem('User')) as User;
  }

  removeUser(): void {
    sessionStorage.removeItem('User');
  }

  setIdToken(token): void {
    sessionStorage.setItem('id_token', token.rawData);
  }

  getIdToken() {
    return sessionStorage.getItem('id_token');
  }

  removeIdToken() {
    sessionStorage.removeItem('id_token');
  }

  setRedirectUrl(url: string): void {
    localStorage.setItem('RedirectUrl', url);
  }

  getRedirectUrl(): string {
    return localStorage.getItem('RedirectUrl');
  }

  removeRedirectUrl(): void {
    localStorage.removeItem('RedirectUrl');
  }

  setRedirectQueryParams(paramsMap: ParamMap): void {
    localStorage.setItem('RedirectParams', JSON.stringify(paramsMap['params']));
  }

  getRedirectQueryParams(): string {
    return localStorage.getItem('RedirectParams');
  }

  removeRedirectQueryParams(): void {
    localStorage.removeItem('RedirectParams');
  }

  setState(state: string): void {
    localStorage.setItem('State', state);
  }

  getState(): string {
    return localStorage.getItem('State');
  }

  removeState(): void {
    localStorage.removeItem('State');
  }

  setExpiresAt(exp: string) {
    const expires_at = exp + '000';
    sessionStorage.setItem('id_token_expires_at', expires_at);
  }

  getExpiresAt(): string {
    return sessionStorage.getItem('id_token_expires_at');
  }

  removeExpiresAt(): void {
    sessionStorage.removeItem('id_token_expires_at');
  }

  setCodeVerifier(codeVerifier: string): void {
    localStorage.setItem('CodeVerifier', codeVerifier);
  }

  getCodeVerifier(): string {
    return localStorage.getItem('CodeVerifier');
  }

  removeCodeVerifier(): void {
    localStorage.removeItem('CodeVerifier');
  }
}
